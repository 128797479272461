










































































import Contratista from '@/entidades/Plantacion/Contratista';
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Validations} from 'vuelidate-property-decorators';
import {required,email} from 'vuelidate/lib/validators'
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {Mutation,State} from 'vuex-class'
@Component
export default class FormularioApp extends Vue 
{
    @State('sistemaId',{namespace:'authModule'}) sistemaId!:number;
     @Prop({type:Number,required:true})
    action!:number;

    @Prop({type:String,required:true})
    titulo!:string;


    @Prop({required:true})
    modelo!:Contratista;


     @Validations()
     validations = {
         documento : {required},
         vendorSap : {required},
         razonSocial : {required},
         email : {required,email}

     }
     documento:string="";
     vendorSap:string ="";
     razonSocial:string ="";
     email:string = "";
     contratistaId:number=0
     tipo:number=1;
     //tipos:[{id:number,descripcion:string}] =[{'id':0,'descripcion':''}];
     tipos:{'id':number,'descripcion':string}[]=[];
     //Metodos
     guardarInfo()
    {
        this.modelo.contratistaId = this.contratistaId;
        this.modelo.razonSocial = this.razonSocial;
        this.modelo.email = this.email;
        this.modelo.documento = this.documento;
        this.modelo.vendorSap = this.vendorSap;
        this.$emit('guardar');
    }

     //Propiedas computadas

       //Computed
    get texto()
    {
        if(this.action == 1)
        {
            return "GUARDAR";
        }
        else
        {
            this.documento = this.modelo.documento;
            this.razonSocial = this.modelo.razonSocial;
            this.email = this.modelo.email;
            this.contratistaId = this.modelo.contratistaId;
            this.vendorSap = this.modelo.vendorSap;
            this.tipo = this.modelo.tipo;
            return "EDITAR";
        }
    }

     mounted() {
     this.tipos.push({id:1,descripcion:'PLANTABAL'});
     this.tipos.push({id:2,descripcion:'EXTERNO'});
    }
}
