import PeopleBase from "./PeopleBase";
import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import {Obtener,Save,Editar} from '../../Generico/Conexion';
import { mainAxios } from "@/plugins/axios";

export class ContratistaPo 
{
  contratistaId:number=0;
  contratistaPoId:number = 0;
  contratistaName:string="";
  po:string="";
  plantaId:number=0;
  poSimple:string="";
  material:string="";
}

export default class Contratista extends PeopleBase
{
    contratistaId:number=0;
    razonSocial:string="";
    sistemaId:number=0;
    tipo:number=0;
    tipoDescripcion:string="";
    static async Obtener(sitemaId:number):Promise<ResponseCustom<Contratista>>
    {
      return await Obtener<Contratista>(`Contratista/Obtener/${sitemaId}`,mainAxios);
    }
    static async ObtenerPo(constratistaId:number,plantaId:number):Promise<ResponseCustom<ContratistaPo>>
    {
      return await Obtener<ContratistaPo>(`Contratista/ObtenerContratistPo/${constratistaId}/${plantaId}`,mainAxios);
    }
    static async ObtenerPoAll():Promise<ResponseCustom<ContratistaPo>>
    {
      return await Obtener<ContratistaPo>('Contratista/ObtenerContratistPoAll/',mainAxios);
    }
    static async GuardarContrastitaPo(request:ContratistaPo):Promise<ResponseGenerico>
    {
      return await Save<ContratistaPo>('Contratista/GuardarContrastitaPo/',request,mainAxios);
    }

    static async ObtenerSelect(sistemaId:number):Promise<ResponseCustom<Contratista>>
    {
      return await Obtener<Contratista>(`Contratista/ObtenerSelect/${sistemaId}`,mainAxios);
    }

    static async Guardar(contratista:Contratista):Promise<ResponseGenerico>
    {
        return await Save<Contratista>("Contratista/Guardar",contratista,mainAxios);
    }

    static async EditarEstado(contratista:Contratista):Promise<ResponseGenerico>
    {
        return await Editar<Contratista>("Contratista/EditarEstado",contratista,mainAxios);
    }

    static async Editar(contratista:Contratista):Promise<ResponseGenerico>
    {
        return await Editar<Contratista>("Contratista/Editar",contratista,mainAxios);
    }


    
}